import React, { Component } from 'react';
import PropTypes from 'prop-types';
import fieldMapper from './fields/fieldMapper';
import FieldDecorator from './libs/FieldDecorator';
import { FORM_OPERATION_READ } from '../../config/constants';


class Form extends Component {
  constructor(props) {
    super(props);
    this.getComponents = this.getComponents.bind(this);
    this.getFieldFromFieldType = this.getFieldFromFieldType.bind(this);
    this.onFieldValueChange = this.onFieldValueChange.bind(this);
  }

  onFieldValueChange(updatedFormValue) {
    const { onFieldValueChange } = this.props;

    onFieldValueChange({ values: updatedFormValue });
  }

  // eslint-disable-next-line class-methods-use-this
  getFieldFromFieldType(field, operation) {
    const { control: { type } } = field;
    const Component = operation === FORM_OPERATION_READ ?
      fieldMapper.readOnlyField :
      fieldMapper[type];

    return Component;
  }


  getComponents(formData, operation) {
    return formData.filter(field => !field.hidden).map((field) => {
      const Component = this.getFieldFromFieldType(field, operation);

      return (
        <FieldDecorator
          fieldData={field}
          key={field.id}
          onChange={this.onFieldValueChange}
          operation={operation}
        >
          {
            (extraProps => (
              <Component
                {...extraProps}
                formData={formData}
              />
            ))
          }
        </FieldDecorator>
      );
    });
  }

  render() {
    const { formData = [], operation } = this.props;
    const components = this.getComponents(formData, operation);

    return (
      <form>{components}</form>
    );
  }
}

Form.propTypes = {
  onFieldValueChange: PropTypes.func.isRequired,
  formData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  operation: PropTypes.string.isRequired,
};

export default Form;
