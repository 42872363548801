import React from 'react';
import PropType from 'prop-types';
import { FORM_OPERATION_READ } from '../../../config/constants';


const FieldDecorator = (props) => {
  const {
    onChange,
    children,
    fieldData,
    operation,
  } = props;

  const {
    label = '',
    id,
    value: { errors = [] },
  } = fieldData;

  const onChangeFieldValueChange = (value) => {
    onChange({ [id]: value });
  };

  if (operation === FORM_OPERATION_READ) {
    return (
      <div className="form-field__contianer-readonly">
        <label className="form-field__label-container-readonly" htmlFor={id}>{`${label}: `}</label>
        {
          children({ onChange: onChangeFieldValueChange, ...fieldData })
        }
      </div>
    );
  }

  return (
    <div className="form-field__container">
      <div className="form-field__label-container">
        <label htmlFor={id} className={`form-field__label-text-${errors.length && 'error'}`}>{label}</label>
      </div>
      <div className="form-field__field-container">
        {
          children({ onChange: onChangeFieldValueChange, ...fieldData })
        }
      </div>
      <div className="form-field__field-error-container">
        {
          errors.map(error => (
            <div className="form-field__field-error" key={error}>
              <span>{error}</span>
            </div>
          ))
        }
      </div>
    </div>
  );
};

FieldDecorator.propTypes = {
  onChange: PropType.func.isRequired,
  children: PropType.func.isRequired,
  fieldData: PropType.shape({
    label: PropType.string.isRequired,
    id: PropType.string.isRequired,
    value: PropType.shape({
      errors: PropType.arrayOf(PropType.shape({})).isRequired,
    }).isRequired,
  }).isRequired,
  operation: PropType.string.isRequired,
};

export default FieldDecorator;
