import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import getPromotionType from '../../shared/helpers/getPromotionType';
import CampaignRow from './CampaignRow';


const PromotionRow = function PromotionRowComponent({
  name, details, campaigns, discountType, odd, uuid,
}) {
  const rowClasses = classNames(
    'promotions__row',
    { 'promotions__row--odd': odd },
  );
  const rowSpan = campaigns.reduce(
    (total, campaign) => (total + campaign.coupons.length), 0,
  );

  return campaigns.map((campaign, campaignIdx) => (
    campaign.coupons.map(({ uuid: couponUuid, totalCodes }, couponIdx) => (
      <tr className={rowClasses} key={`${campaign.uuid}-${couponUuid}`}>
        {campaignIdx === 0 && couponIdx === 0 && (
          <td className="promotions__promotion" rowSpan={rowSpan}>
            <div className="promotions__promotion-details">
              <div>
                {name}
                <div className="promotions__promotion-description">{details}</div>
              </div>
              <Link to={`/promotions/edit/${uuid}`} className="promotions__promotion-edit">Edit Promotion</Link>
            </div>
          </td>
        )}
        <CampaignRow
          {...campaign}
          totalCodes={totalCodes}
          type={getPromotionType(discountType)}
        />
      </tr>
    ))
  ));
};

PromotionRow.propTypes = {
  name: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  discountType: PropTypes.string.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    coupons: PropTypes.arrayOf(PropTypes.shape({
      totalCodes: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
  odd: PropTypes.bool.isRequired,
};

export default PromotionRow;
