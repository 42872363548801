import React, { Component } from 'react';
import { connect } from 'react-redux';
import thunk from 'redux-thunk';
import {
  createStore, applyMiddleware, compose, combineReducers,
} from 'redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconBack from '../shared/icons/icon-back.svg';
import CampaignCard from '../shared/components/CampaignCard/CampaignCard';
import {
  getCampaign,
  getCurrentStore,
} from './actions/campaign';
import Tabs from '../shared/components/Tabs/Tabs';
import PromotionsWrapper from './components/PromotionsWrapper';
import CouponsWrapper from './components/CouponsWrapper';
import createStatusToastReducer from '../shared/components/StatusToast/reducers';
import createCampaignReducer from './reducers/campaign';
import StatusToast from '../shared/components/StatusToast';


const mapStateToProps = state => ({
  store: state.campaignData.store,
  campaign: state.campaignData.campaign,
  promotions: state.campaignData.promotions,
  allPromotions: state.campaignData.allPromotions,
});

class Campaign extends Component {
  static getStore() {
    const middleware = [thunk];

    const initialState = {
      campaignData: {
        campaign: null,
        store: { name: '' },
        promotions: [],
        coupons: [],
        allPromotions: [],
      },
      statusToast: {
        messages: [],
      },
    };

    // eslint-disable-next-line no-underscore-dangle
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const statusToastReducer = createStatusToastReducer(initialState.statusToast);
    const campaignReducer = createCampaignReducer(initialState.campaignData);

    return createStore(
      combineReducers({
        campaignData: campaignReducer,
        statusToast: statusToastReducer,
      }),
      initialState,
      composeEnhancer(applyMiddleware(...middleware)),
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      tabs: [{
        title: 'Promotions',
        disabled: false,
        key: 'promotions',
      }, {
        title: 'Coupons',
        disabled: true,
        key: 'coupons',
      }],
    };
  }

  componentDidMount() {
    const { dispatch, campaignId } = this.props;

    dispatch(getCurrentStore());
    dispatch(getCampaign(campaignId));
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { promotions = [] } = nextProps;
    const newState = { ...state };
    const couponsTab = newState.tabs.find(tab => tab.key === 'coupons');

    couponsTab.disabled = !promotions.length > 0;

    return newState;
  }

  // eslint-disable-next-line max-lines-per-function
  render() {
    const {
      campaign, promotions, campaignId,
    } = this.props;
    const {
      tabs,
    } = this.state;

    return (
      <StatusToast>
        <div className="campaign__wrapper">
          <div className="campaign__container">
            <div className="campaign__header">
              <div className="campaign__header-item campaign__header-item-header-text">
                <Link to="/campaigns" className="campaign__back">
                  <IconBack className="campaign__back-icon" />
                  Campaign
                </Link>
              </div>
            </div>
            <ul className="campaign__list">
              {campaign && (
                <CampaignCard
                  key={campaign.uuid}
                  disableLongDescription={false}
                  clickable={false}
                  {...campaign}
                />
              )}
            </ul>
          </div>
        </div>
        <Tabs tabsConfiguration={tabs} defaultTab="promotions">
          <div key="promotions" className="promotion__container">
            <PromotionsWrapper
              campaignId={campaignId}
              campaignPromotions={promotions}
            />
          </div>
          <div key="coupons" className="coupon__container">
            <CouponsWrapper
              campaignId={campaignId}
            />
          </div>
        </Tabs>
      </StatusToast>
    );
  }
}

Campaign.propTypes = {
  dispatch: PropTypes.func.isRequired,
  campaignId: PropTypes.string.isRequired,
  store: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  campaign: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }),
  promotions: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  })).isRequired,
  allPromotions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Campaign.defaultProps = {
  campaign: null,
};

export default connect(mapStateToProps)(Campaign);
