import { RECEIVE_STORES } from '../actions/types';


export default function storesReducer(state, action) {
  switch (action.type) {
    case RECEIVE_STORES:
      return {
        ...state,
        stores: action.stores,
      };
    default:
      return state;
  }
}
