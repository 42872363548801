import React, { Component } from 'react';
import { connect } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import PropTypes from 'prop-types';
import IconCaret from '../shared/icons/icon-caret.svg';
import IconSort from '../shared/icons/icon-sort.svg';
import promotionsReducer from './reducers/promotions';
import { getPromotions, getCurrentStore } from './actions/promotions';
import PromotionRow from './components/PromotionRow';


const mapStateToProps = state => ({
  promotions: state.promotions,
  store: state.store,
});

class Promotions extends Component {
  static getStore() {
    const middleware = [thunk];

    const initialState = {
      promotions: [],
      store: { name: '' },
    };

    return createStore(
      promotionsReducer,
      initialState,
      applyMiddleware(...middleware),
    );
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getCurrentStore());
    dispatch(getPromotions());
  }

  /* eslint-disable max-lines-per-function */
  render() {
    const {
      store: { name },
      promotions,
    } = this.props;

    return (
      <>
        <div className="promotions__header-container">
          <h1 className="promotions__title">{`${name} Promotions`}</h1>
          <a className="promotions__create-button" href="/promotions/create">
            Create Promotion
          </a>
        </div>
        <div className="promotions-wrapper">
          <table className="promotions">
            <thead>
              <tr className="promotions__header">
                <th className="promotions__header-promotion">
                  <span className="text-color"> Promotion</span>
                  <IconSort className="promotions__sort-icon" />
                </th>

                <th className="promotions__header-campaign">
                  <span className="text-color">Campaign</span>
                </th>

                <th className="promotions__header-dates">
                  <span className="text-color"> Campaign Valid Dates</span>
                  <IconSort className="promotions__sort-icon" />
                </th>

                <th className="promotions__header-status">
                  <span className="text-color">Status</span>
                  <IconCaret className="promotions__filter-icon" />
                </th>

                <th className="promotions__header-codes">
                  <span className="text-color">Codes Available</span>
                </th>

                <th className="promotions__header-type">
                  <span className="text-color">Discount Type</span>
                </th>
                <th className="promotions__header-created">
                  <span className="text-color">Date Created</span>
                  <IconSort className="promotions__sort-icon" />
                </th>
              </tr>
            </thead>
            <tbody>
              {promotions.map((promotion, idx) => (
                <PromotionRow
                  key={promotion.uuid}
                  {...promotion}
                  odd={idx % 2 === 1}
                />
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

Promotions.propTypes = {
  dispatch: PropTypes.func.isRequired,
  store: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  promotions: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default connect(mapStateToProps)(Promotions);
