import { v4 } from 'uuid';
import { CLOSE_MESSAGE, SHOW_MESSAGES } from '../actions/types';


const createStatusToastReducer = initialState => (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MESSAGES: {
      const { messages = [] } = action.payload;

      const updatedMessages = messages.map(message => ({
        ...message,
        id: v4(),
      }));

      return {
        ...state,
        messages: [...state.messages, ...updatedMessages],
      };
    }

    case CLOSE_MESSAGE: {
      const id = action.payload;
      const messageIndex = state.messages.findIndex(message => message.id === id);
      const newMessages = state.messages;

      newMessages.splice(messageIndex, 1);

      return {
        ...state,
        messages: [...newMessages],
      };
    }

    default: {
      return state;
    }
  }
};

export default createStatusToastReducer;
