import {
  RECEIVE_CURRENT_STORE,
  RECEIVE_CAMPAIGN,
  RECEIVE_ALL_PROMOTIONS,
  RECEIVE_COUPONS_FOR_CAMPAIGN,
} from '../actions/types';


const createCampaignReducer = initialState => (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_CURRENT_STORE:
      return {
        ...state,
        store: action.store,
      };
    case RECEIVE_CAMPAIGN:
      return {
        ...state,
        campaign: action.campaign,
        promotions: action.campaign.promotions,
        coupons: action.campaign.coupons,
      };
    case RECEIVE_ALL_PROMOTIONS: {
      const { allPromotions } = action.payload;

      return {
        ...state,
        allPromotions,
      };
    }
    case RECEIVE_COUPONS_FOR_CAMPAIGN: {
      const { coupons } = action.payload;

      return {
        ...state,
        coupons,
      };
    }
    default:
      return state;
  }
};

export default createCampaignReducer;
