import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../shared/icons/logo.svg';
import NavigationItems from './components/NavigationItems';


const Navigation = function NavigationComponent({ items, currentStore }) {
  return (
    <div className="navigation__sidebar">
      <span className="navigation__header">
        <Logo className="navigation__header-icon" />
      </span>
      <div className="navigation__items">
        <NavigationItems
          navigationItems={items}
          isNavDisabled={!currentStore}
        />
      </div>
    </div>
  );
};


Navigation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentStore: PropTypes.string,
};

Navigation.defaultProps = {
  currentStore: '',
};

export default Navigation;
