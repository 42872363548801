import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Provider } from 'react-redux';
import StatusBadge from '../StatusBadge/StatusBadge';
import IconEdit from '../../icons/icon-edit.svg';
import Accordion from '../Accordion/Accordion';
import CreateResource from '../../../CreateEditResource/CreateResource';
import { FORM_OPERATION_READ } from '../../../../config/constants';
import Tooltip from '../Tooltip/Tooltip';


const LinkComponent = ({ children, clickable, uuid }) => (clickable ?
  <Link to={`/campaigns/${uuid}`}>{children}</Link> :
  <div className="link-component__disabled-link">{children}</div>);

// eslint-disable-next-line max-lines-per-function
const CampaignCard = function CampaignCardComponent({
  // eslint-disable-next-line react/prop-types
  name,
  uuid,
  starts_at: startsAt,
  ends_at: endsAt,
  status,
  promotions,
  clickable,
  disableLongDescription,
}) {
  return (
    <li className="campaign-card">
      <LinkComponent clickable={clickable} uuid={uuid}>
        <Accordion
          disableLongDescription={disableLongDescription}
          defaultState={false}
          getShortDescription={() => (
            <div className="campaign-card__details">
              <div className="campaign-card__details-top">
                <span className="campaign-card__name">{name}</span>
                <Link className="campaign-card__edit" to={`/campaigns/edit/${uuid}`}>
                  <Tooltip message="Edit Campaign">
                    <IconEdit
                      className="campaign-card__edit-icon"
                    />
                  </Tooltip>
                </Link>
              </div>
              <div className="campaign-card__details-bottom">
                <div className="campaign-card__dates">
                  {startsAt}
                  &ndash;
                  {endsAt || 'Ongoing'}
                  <br />
                  <span className="campaign-card__promo-count">
                    {`${promotions.length} promotion${promotions.length === 1 ? '' : 's'}`}
                  </span>
                </div>
                <div className="campaign-card__state">
                  <StatusBadge status={status} />
                </div>
              </div>
            </div>
          )}
          getLongDescription={() => (
            <Provider store={CreateResource.getStore('campaigns')}>
              <CreateResource
                title={name}
                resource="campaigns"
                resourceDetails={{
                  operation: FORM_OPERATION_READ,
                  resourceId: uuid,
                  extraParams: {
                    queryParams: '',
                    bodyParams: {},
                  },
                }}
              />
            </Provider>
          )}
        />
      </LinkComponent>
    </li>
  );
};

CampaignCard.propTypes = {
  name: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  // eslint-disable-next-line camelcase
  starts_at: PropTypes.string.isRequired,
  // eslint-disable-next-line camelcase
  ends_at: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  promotions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  clickable: PropTypes.bool,
  disableLongDescription: PropTypes.bool.isRequired,
};

LinkComponent.propTypes = {
  children: PropTypes.element.isRequired,
  clickable: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
};

CampaignCard.defaultProps = {
  clickable: true,
};

export default CampaignCard;
