import { CLOSE_MESSAGE, SHOW_MESSAGES } from './types';


const closeMessage = id => ({
  type: CLOSE_MESSAGE,
  payload: id,
});

const showMessages = messages => ({
  type: SHOW_MESSAGES,
  payload: { messages },
});

export {
  closeMessage,
  showMessages,
};
