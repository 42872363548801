import axios from 'axios';
import { FETCH_SCHEMA_ERROR, FETCH_SCHEMA_SUCCESS } from '../types/fetchSchema';
import { UPDATE_FORM_DATA } from '../types/formActions';
import { resourceSelector } from '../selectors/formDataSelectors';
import { FORM_OPERATION_EDIT, FORM_OPERATION_READ } from '../../../../config/constants';


export const fetchSchemaSuccess = (resource, payload) => ({
  type: `${FETCH_SCHEMA_SUCCESS}_${resource}`,
  payload,
});

export const updateFormData = (resource, formData) => ({
  type: `${UPDATE_FORM_DATA}_${resource}`,
  payload: formData,
});

export const fetchSchemaError = (resource, payload) => ({
  type: `${FETCH_SCHEMA_ERROR}_${resource}`,
  payload,
});

export const fetchSchema = resource => (dispatch, getState) => {
  axios.get(`/api/${resource}/schema`)
    .then((response) => {
      const { data: responseData } = response;

      dispatch(fetchSchemaSuccess(resource, responseData));

      const state = getState();
      const {
        resourceId,
        operation,
        extraParams: { queryParams = '' } = {},
      } = resourceSelector(state.resource);

      if (operation === FORM_OPERATION_EDIT || operation === FORM_OPERATION_READ) {
        axios.get(`/api/${resource}/${resourceId}?${queryParams}`)
          .then((resourceData) => {
            const { data: editResourceData } = resourceData;

            dispatch(updateFormData(resource, editResourceData));
          })
          .catch(console.error);
      }
    })
    .catch(console.error);
};
