import React, { Component } from 'react';
import { connect } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import PropTypes from 'prop-types';
import CampaignCard from '../shared/components/CampaignCard/CampaignCard';
import campaignsReducer from './reducers/campaigns';
import { getCampaigns, getCurrentStore } from './actions/campaigns';


const mapStateToProps = state => ({
  campaigns: state.campaigns,
  store: state.store,
});

class Campaigns extends Component {
  static getStore() {
    const middleware = [thunk];

    const initialState = {
      campaigns: [],
      store: { name: '' },
    };

    return createStore(
      campaignsReducer,
      initialState,
      applyMiddleware(...middleware),
    );
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getCurrentStore());
    dispatch(getCampaigns());
  }

  render() {
    const { store: { name }, campaigns } = this.props;

    return (
      <>
        <div className="campaigns__header-container">
          <h1 className="campaigns__title">{`${name} Campaigns`}</h1>
          <a className="campaigns__create-button" href="/campaigns/create">Create Campaign</a>
        </div>
        <div className="campaigns__wrapper">
          <div className="campaigns__container">
            <div className="campaigns__header">
              {/* TODO: remove title when sort is added */}
              <div className="campaigns__header-item campaign__header-item-header-text">
                Campaigns
              </div>
            </div>
            <ul className="campaigns__list campaign__list-card-container">
              {campaigns.map(campaign => (
                <CampaignCard
                  key={campaign.uuid}
                  disableLongDescription
                  {...campaign}
                />
              ))}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

Campaigns.propTypes = {
  dispatch: PropTypes.func.isRequired,
  store: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  })).isRequired,
};

export default connect(mapStateToProps)(Campaigns);
