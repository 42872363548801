/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';


const ComponentWrapper = ({ children }) => {
  const {
    isAuthenticated, loginWithRedirect, isLoading, getAccessTokenSilently,
  } = useAuth0();

  const {
    AUTH0_AUDIENCE,
    AUTH0_SCOPE,
  } = window.upCouponAdmin.globals;

  const [tokenAvaialable, setTokenAvailable] = useState(false);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: AUTH0_AUDIENCE,
            scope: AUTH0_SCOPE,
          },
        });

        axios.interceptors.request.use(
          config => ({
            ...config,
            headers: { ...config.headers, Authorization: `Bearer ${token}` },
          }),
        );

        setTokenAvailable(true);
      } catch (error) {
        console.log(error);
      }
    };

    getAccessToken();
  }, []);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (!isLoading && !isAuthenticated) {
    loginWithRedirect({ userLocation: window.origin });
    return <h1>Loading...</h1>;
  }

  if (!tokenAvaialable) {
    return <h1>Loading token...</h1>;
  }


  return children;
};

export default ComponentWrapper;
