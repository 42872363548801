import React, { Component } from 'react';
import { connect } from 'react-redux';
import thunk from 'redux-thunk';
import {
  createStore, applyMiddleware, compose, combineReducers,
} from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fetchSchema } from '../shared/redux/actions/schema';
import createResourceReducer from './reducers/createResourceReducer';
import formDataSelector, { formValiditySelector } from '../shared/redux/selectors/formDataSelectors';
import updateFormValues, { patchFormValues, submitFormValues } from '../shared/redux/actions/formActions';
import Form from '../Forms';
import createStatusToastReducer from '../shared/components/StatusToast/reducers';
import StatusToast from '../shared/components/StatusToast';
import storeResourceDetails from '../shared/redux/actions/resourceActions';
import {
  FORM_OPERATION_BTN_LABEL, FORM_OPERATION_CREATE, FORM_OPERATION_EDIT, FORM_OPERATION_READ,
} from '../../config/constants';


const mapStateToProps = state => ({
  formData: formDataSelector(state.resource),
  isErrorPresent: formValiditySelector(state.resource),
});

class CreateResource extends Component {
  static getStore(resource) {
    const middleware = [thunk];

    const initialState = {
      resource: {
        formData: [],
        resourceDetails: {},
      },
      statusToast: {
        messages: [],
      },
    };

    // eslint-disable-next-line no-underscore-dangle
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const resourceReducer = createResourceReducer(resource, initialState.resource);
    const statusToastReducer = createStatusToastReducer(initialState.statusToast);

    return createStore(
      combineReducers({
        resource: resourceReducer,
        statusToast: statusToastReducer,
      }),
      initialState,
      composeEnhancer(applyMiddleware(...middleware)),
    );
  }

  constructor(props) {
    super(props);
    this.updateFormData = this.updateFormData.bind(this);
    this.submitFormData = this.submitFormData.bind(this);
  }

  componentDidMount() {
    const { dispatch, resource, resourceDetails } = this.props;

    dispatch(storeResourceDetails(resource, resourceDetails));
    dispatch(fetchSchema(resource));
  }

  updateFormData(formData) {
    const { dispatch, resource } = this.props;

    dispatch(updateFormValues(resource, formData));
  }

  submitFormData() {
    const {
      dispatch, resource, formData, resourceDetails,
    } = this.props;
    const { operation, resourceId } = resourceDetails;

    if (operation === FORM_OPERATION_EDIT) {
      dispatch(patchFormValues(resource, formData, resourceId));
    } else {
      dispatch(submitFormValues(resource, formData));
    }
  }

  render() {
    const {
      formData = [], title = '', isErrorPresent, resourceDetails,
    } = this.props;
    const { operation } = resourceDetails;

    const buttonClasses = classNames({
      'create-resource__footer__submit-button': !isErrorPresent,
      'create-resource__footer__submit-button-disabled': isErrorPresent,
    });

    return (
      <StatusToast>
        <div className="create-resource__container">
          <div className="create-resource__header">
            <h1 className="create-resource__header-text">{title}</h1>
          </div>
          <div className="create-resource__form-container">
            <Form
              formData={formData}
              onFieldValueChange={this.updateFormData}
              operation={operation}
            />
            { operation !== FORM_OPERATION_READ && (
            <div className="create-resource__footer">
              <div className="create-resource__footer__submit">
                <button
                  type="button"
                  disabled={isErrorPresent}
                  onClick={this.submitFormData}
                  className={buttonClasses}
                >
                  {FORM_OPERATION_BTN_LABEL[operation]}
                </button>
              </div>
            </div>
            )}
          </div>
        </div>
      </StatusToast>
    );
  }
}

CreateResource.propTypes = {
  dispatch: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
  formData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
  isErrorPresent: PropTypes.bool.isRequired,
  resourceDetails: PropTypes.shape({
    resourceId: PropTypes.string.isRequired,
    operation: PropTypes.string,
  }),
};

CreateResource.defaultProps = {
  resourceDetails: {
    operation: FORM_OPERATION_CREATE,
  },
};

export default connect(mapStateToProps)(CreateResource);
