import InputField from './InputField';
import RadioField from './RadioField';
import ReadOnlyField from './ReadOnlyField';
import SelectField from './SelectField';
import TextAreaField from './TextAreaField';


const fieldMapper = {
  text: InputField,
  textArea: TextAreaField,
  radio: RadioField,
  date: InputField,
  select: SelectField,
  readOnlyField: ReadOnlyField,
  number: InputField,
};

export default fieldMapper;
