import React from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import StoreSelectorContainer from '../StoreSelector/components/StoreSelectorContainer/StoreSelectorContainer';


const SiteHeader = (props) => {
  const { logoutUrl, currentStore } = props;
  const { ENABLE_AUTH0 } = window.upCouponAdmin.globals;
  const { logout } = useAuth0();

  const getLogoutComponent = () => {
    // eslint-disable-next-line no-undef
    if (ENABLE_AUTH0 === 'true') {
      return (
        <button
          type="button"
          className="site-header__logout-CTA"
          onClick={() => {
            logout({ logoutParams: { returnTo: window.location.origin } });
          }}
        >
          <div className="site-header__logout-CTA--text">
            Log out
          </div>
        </button>
      );
    }
    return (
      <a href={logoutUrl} className="site-header__logout-CTA">
        <div className="site-header__logout-CTA--text">
          Log out
        </div>
      </a>
    );
  };

  return (
    <div className="site-header__container">
      { !currentStore && <div className="site-header__title">Campaign Manager</div> }
      { currentStore && currentStore.length &&
        <StoreSelectorContainer currentStore={currentStore} /> }
      {
        getLogoutComponent()
      }
    </div>
  );
};


SiteHeader.propTypes = {
  logoutUrl: PropTypes.string.isRequired,
  currentStore: PropTypes.string.isRequired,
};

export default SiteHeader;
