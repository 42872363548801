import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { closeMessage } from './actions';
import { ERROR, SUCCESS } from '../../redux/types/responseResult';


const TIMEOUT = 3000;
const mapStateToProps = state => ({
  messages: state.statusToast.messages,
});

const Message = ({ message, onClose }) => {
  useEffect(() => {
    setTimeout(() => onClose(message.id), TIMEOUT);
  }, []);

  const classes = classNames({
    'status-toast__message-container-error': message.type === ERROR,
    'status-toast__message-container-success': message.type === SUCCESS,
  });

  return (
    <div className={`status-toast__message-container ${classes}`}>
      <div className="status-toat__container-message">{message.message}</div>
    </div>
  );
};


const StatusToasts = (props) => {
  const { messages = [], children, dispatch } = props;

  const onClose = (id) => {
    dispatch(closeMessage(id));
  };

  return (
    <>
      <div className="status-toast__container">
        {
                messages.map(message => (
                  <Message key={message.id} message={message} onClose={onClose} />
                ))
            }
      </div>
      {children}
    </>
  );
};

StatusToasts.propTypes = {
  messages: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
};

Message.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(StatusToasts);
