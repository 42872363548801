import React from 'react';
import PropTypes from 'prop-types';


const AssignPromotion = ({ allPromotions, onSelectPromotion }) => (
  <div className="assign-promotions__container">
    <div className="assign-promotion__select-container">
      {
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label htmlFor="promotion_select" className="assign-promotion__label">Select Promotion</label>
      }
      <select
        id="promotion_select"
        className="assign-promotion__select"
        onChange={(event) => {
          onSelectPromotion(event.target.value);
        }}
      >
        <option value="" disabled selected>Select Promotion</option>
        {
            allPromotions.map(promotion => (
              <option value={promotion.uuid} key={promotion.uuid}>{promotion.calloutMsg}</option>
            ))
        }
      </select>
    </div>
  </div>
);

AssignPromotion.propTypes = {
  allPromotions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectPromotion: PropTypes.func.isRequired,
};

export default AssignPromotion;
