import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import validator from '../libs/validator';


const SelectField = ({
  id,
  fieldKey,
  control,
  onChange,
  options: selectOptions = [],
  validation,
  value,
  formData,
}) => {
  const { type } = control;

  const validateField = (userValue) => {
    const errors = validator(userValue, value.initialValue, validation, formData);

    onChange({ value: userValue, isTouched: userValue !== value.initialValue, errors });
  };

  useEffect(() => {
    validateField(value.initialValue);
  }, [value.initialValue, value.formResetUUID]);

  return (
    <select
      className="form-field__control-select"
      id={`${id}`}
      type={type}
      value={value.value}
      name={fieldKey}
      onChange={(event) => {
        validateField(event.target.value);
      }}
    >
      {selectOptions.map(({ label, value }) => (
        <option value={value} label={label} key={`${value}_${label}`} />
      ))
  }
    </select>
  );
};

SelectField.propTypes = {
  control: PropTypes.shape({
    type: PropTypes.string.isRequired,
    options: PropTypes.shape({
      inputProps: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
    initialValue: PropTypes.string.isRequired,
    formResetUUID: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  validation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formData: PropTypes.shape({}).isRequired,
};

export default SelectField;
