import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const statusMap = {
  active: 'Active',
  pending: 'Pending',
  expired: 'Expired',
  disabled: 'Disabled',
};

const StatusBadge = ({
  status, size,
}) => {
  const classes = classNames(
    'status-badge',
    {
      [`status-badge--${size}`]: size,
      [`status-badge--${status}`]: status,
    },
  );

  return (
    <span className={classes}>{statusMap[status]}</span>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.string,
  size: PropTypes.string,
};

StatusBadge.defaultProps = {
  status: 'active',
  size: null,
};

export default StatusBadge;
