import React from 'react';
import PropTypes from 'prop-types';


const ReadOnlyField = ({
  value: { value = '' },
  id,
  control,
  options,
}) => {
  // eslint-disable-next-line complexity
  const getMappedValue = (inputValue) => {
    const {
      type,
      options: {
        fieldOptions: {
          dataType,
        } = {},
      } = {},
    } = control;

    switch (type) {
      case 'textArea': {
        if (dataType === 'json') {
          return (
            <pre className="form-field__control-readonly-pre">
              { JSON.stringify(inputValue, undefined, 4) }
            </pre>
          );
        }
        return String(inputValue);
      }

      case 'text':
      case 'number': {
        return String(inputValue);
      }

      case 'radio': {
        if (dataType === 'boolean') {
          return String(inputValue);
        }
        const option = options.find((option) => {
          const { value } = option;

          return value === inputValue;
        });

        return (option && option.label) || 'Invalid option selected';
      }

      default: return inputValue;
    }
  };


  return (<div id={id} className="form-field__control-readonly">{getMappedValue(value)}</div>);
};

ReadOnlyField.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  control: PropTypes.shape({
    type: PropTypes.string.isRequired,
    options: PropTypes.shape({
      fieldOptions: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ReadOnlyField;
