import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import Navigation from '../Navigation/Navigation';
import LandingPage from '../LandingPage/LandingPage';
import Campaigns from '../Campaigns/Campaigns';
import Campaign from '../Campaign/Campaign';
import Promotions from '../Promotions/Promotions';
import CreateResource from '../CreateEditResource/CreateResource';
import { FORM_OPERATION_CREATE, FORM_OPERATION_EDIT } from '../../config/constants';
import withAuthentication from '../lib/withAuthentication';
import SiteHeader from '../SiteHeader/SiteHeader';


class CouponAdmin extends Component {
  static isUserBlockingOnError() {
    return false;
  }

  static getDomId() {
    return 'coupon_admin';
  }

  // eslint-disable-next-line max-lines-per-function
  constructor(props) {
    super(props);
    this.createReourceMap = [{
      path: '/campaigns/create',
      resource: 'campaigns',
      component: CreateResource,
      title: 'Create Campaign',
    },
    {
      path: '/promotions/create',
      resource: 'promotions',
      component: CreateResource,
      title: 'Create Promotion',
    },
    {
      path: '/campaigns/coupons/create/:campaignId',
      resource: 'coupons',
      component: CreateResource,
      title: 'Create Coupons',
      dynamicRendering: true,
      dynamicPropsFunction: params => ({
        resourceDetails: {
          resourceId: params.campaignId,
          operation: FORM_OPERATION_CREATE,
          extraParams: {
            bodyParams: {
            // eslint-disable-next-line camelcase
              campaign_uuid: params.campaignId,
            },
            queryParams: '',
          },
        },
      }),
    },
    {
      path: '/promotions/edit/:resourceId',
      resource: 'promotions',
      component: CreateResource,
      title: 'Edit Promotion',
      dynamicRendering: true,
      dynamicPropsFunction: params => ({
        resourceDetails: {
          resourceId: params.resourceId,
          operation: FORM_OPERATION_EDIT,
        },
      }),
    },
    {
      path: '/campaigns/edit/:resourceId',
      resource: 'campaigns',
      component: CreateResource,
      title: 'Edit Campaign',
      dynamicRendering: true,
      dynamicPropsFunction: params => ({
        resourceDetails: {
          resourceId: params.resourceId,
          operation: FORM_OPERATION_EDIT,
        },
      }),
    },
    {
      path: '/campaigns/coupons/edit/:resourceId/:campaignId',
      resource: 'coupons',
      component: CreateResource,
      title: 'Edit Coupon',
      dynamicRendering: true,
      dynamicPropsFunction: params => ({
        resourceDetails: {
          resourceId: params.resourceId,
          operation: FORM_OPERATION_EDIT,
          extraParams: {
            queryParams: `campaignId=${params.campaignId}&`,
            bodyParams: {},
          },
        },
      }),
    },
    {
      path: '/campaigns/campaignPromotions/edit/:campaignId/:promotionId',
      resource: 'campaignPromotions',
      component: CreateResource,
      title: 'Edit Campaign Promotion Association',
      dynamicRendering: true,
      dynamicPropsFunction: params => ({
        resourceDetails: {
          resourceId: '',
          operation: FORM_OPERATION_EDIT,
          extraParams: {
            queryParams: `campaign_uuid=${params.campaignId}&promotion_uuid=${params.promotionId}`,
            bodyParams: {
              // eslint-disable-next-line camelcase
              campaign_uuid: params.campaignId,
              // eslint-disable-next-line camelcase
              promotion_uuid: params.promotionId,
            },
          },
        },
      }),
    }];
  }

  // eslint-disable-next-line max-lines-per-function
  render() {
    const { home, navigation, site_header: siteHeader } = this.props;

    return (
      <BrowserRouter>
        <Navigation {...navigation} />
        <SiteHeader {...siteHeader} />
        <div className="coupon-admin">
          <div className="coupon_admin__body">
            <Switch>
              <Route exact path="/">
                {home && (<LandingPage {...home} />)}
              </Route>
              <Route exact path="/campaigns">
                <Provider store={Campaigns.getStore()}>
                  <Campaigns />
                </Provider>
              </Route>
              {
                this.createReourceMap.map((resourceObj) => {
                  const {
                    path = '', component: Component, resource, title = '', dynamicRendering = false,
                  } = resourceObj;

                  if (dynamicRendering) {
                    const { dynamicPropsFunction } = resourceObj;

                    return (
                      <Route
                        exact
                        key={title}
                        path={path}
                        render={({ match }) => {
                          const dynamicProps = dynamicPropsFunction(match.params);

                          return (
                            <Provider store={Component.getStore(resource)}>
                              <Component title={title} resource={resource} {...dynamicProps} />
                            </Provider>
                          );
                        }}
                      />
                    );
                  }
                  return (
                    <Route
                      exact
                      key={title}
                      path={path}
                    >
                      <Provider store={Component.getStore(resource)}>
                        <Component title={title} resource={resource} />
                      </Provider>
                    </Route>
                  );
                })
              }
              <Route
                exact
                path="/campaigns/:campaignId"
                render={({ match }) => (
                  <Provider store={Campaign.getStore()}>
                    <Campaign campaignId={match.params.campaignId} />
                  </Provider>
                )}
              />
              <Route path="/promotions">
                <Provider store={Promotions.getStore()}>
                  <Promotions />
                </Provider>
              </Route>
              <Route>
                Not Found
              </Route>
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

CouponAdmin.propTypes = {
  home: PropTypes.shape({}),
  navigation: PropTypes.shape({}).isRequired,
  // eslint-disable-next-line camelcase
  site_header: PropTypes.shape({}),
};

CouponAdmin.defaultProps = {
  home: null,
  // eslint-disable-next-line camelcase
  site_header: null,
};

export default withAuthentication(CouponAdmin, 'coupon_admin');
