
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import IconHome from '../../shared/icons/icon-home.svg';
import IconCampaigns from '../../shared/icons/icon-campaigns.svg';
import IconPromotions from '../../shared/icons/icon-promotions.svg';
import IconCoupons from '../../shared/icons/icon-coupons.svg';


const iconMap = {
  home: IconHome,
  campaigns: IconCampaigns,
  promotions: IconPromotions,
  coupons: IconCoupons,
};

const NavigationItems = function NavigationItemsComponent({
  navigationItems,
  isNavDisabled,
}) {
  return navigationItems.map(({
    id, url, name,
  }) => {
    const ItemIcon = iconMap[id];
    const navClass = classNames('navigation__item-link', {
      'navigation__item-link--disabled': isNavDisabled,
    });
    const NavItem = isNavDisabled ? 'div' : NavLink;
    const props = isNavDisabled ? {} : { to: url, activeClassName: 'navigation__item-link--active' };

    return (
      <div className="navigation__item" key={id}>
        <NavItem className={navClass} {...props}>
          <ItemIcon className="navigation__item-icon" />
          {name}
        </NavItem>
      </div>
    );
  });
};

NavigationItems.propTypes = {
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  isNavDisabled: PropTypes.bool.isRequired,
};

export default NavigationItems;
