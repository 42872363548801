/* eslint react/jsx-pascal-case: 0 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import getReduxStore from './getReduxStore';
import getPropsFromWindow from './getPropsFromWindow';
import Boundary from './Boundary';


class Bootstrap {
  constructor(Component) {
    this.domId = Component.getDomId();

    this.domElement = document.getElementById(this.domId);
    this.Component = Component;
    if (this.domElement) {
      this.store = getReduxStore(Component, this.domId);
      this.props = getPropsFromWindow(Component, this.domId);
      this.blockUserOnError = Component.isUserBlockingOnError ?
        Component.isUserBlockingOnError() :
        false;
    }
  }

  init() {
    if (this.domElement) {
      const { dataset: { instanceId } } = this.domElement;

      if (this.store) {
        ReactDOM.render(
          <Provider store={this.store}>
            <Boundary.withStore
              componentId={this.domId}
              blockUserOnError={this.blockUserOnError}
            >
              <this.Component instanceId={instanceId} />
            </Boundary.withStore>
          </Provider>,
          this.domElement,
        );
      } else {
        ReactDOM.render(
          <Boundary.withoutStore
            componentId={this.domId}
            blockUserOnError={this.blockUserOnError}
          >
            <this.Component {...this.props} instanceId={instanceId} />
          </Boundary.withoutStore>,
          this.domElement,
        );
      }
    }
  }
}

export default Bootstrap;
