import React, { Component } from 'react';
import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import storesReducer from './reducers/storeSelector';
import { getStores, setCurrent } from './actions/storeSelector';
import IconCaret from '../shared/icons/icon-caret.svg';


const mapStateToProps = state => ({
  stores: state.stores,
});

class StoreSelector extends Component {
  static getStore() {
    const middleware = [thunk];

    const initialState = {
      stores: [],
    };

    return createStore(
      storesReducer,
      initialState,
      applyMiddleware(...middleware),
    );
  }

  constructor(props) {
    super(props);

    this.updateCurrent = this.updateCurrent.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getStores());
  }

  updateCurrent(event) {
    const { dispatch } = this.props;

    dispatch(setCurrent(event.target.value));
  }

  render() {
    const { stores, currentStore } = this.props;

    return (
      <label className="store-selector" htmlFor="store_selector">
        <select
          className="store-selector__dropdown"
          id="store_selector"
          value={currentStore}
          onChange={this.updateCurrent}
        >
          <option disabled hidden value="">Select a Store</option>
          {stores.map(({ name, uuid }) => (
            <option key={uuid} value={uuid}>{name}</option>
          ))}
        </select>
        <div className="store-selector__icon-container">
          <IconCaret className="store-selector__icon" />
        </div>
      </label>
    );
  }
}

StoreSelector.propTypes = {
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  dispatch: PropTypes.func.isRequired,
  currentStore: PropTypes.string,
};

StoreSelector.defaultProps = {
  currentStore: '',
};

export default connect(mapStateToProps)(StoreSelector);
