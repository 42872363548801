import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconCaret from '../../icons/icon-caret.svg';


const Accordion = ({
  defaultState,
  getShortDescription,
  getLongDescription,
  disableLongDescription,
}) => {
  const [open, setOpen] = useState(defaultState);

  const longDescriptionClasses = classNames(
    'accordion__long-descriptoin-container',
    { 'accordion__long-description-open': open },
  );

  const toggleIconClass = classNames(
    'campaign__promotion-toggle-icon',
    { 'campaign__promotion-toggle-icon--expanded': open },
  );

  return (
    <div className="accordion__container">
      <div
        className="accordion__short-description-container"
        onClick={() => setOpen(!open)}
        onKeyPress={() => setOpen(!open)}
        role="button"
        tabIndex={0}
      >
        {
            getShortDescription()
        }
        { !disableLongDescription && <IconCaret className={toggleIconClass} /> }
      </div>
      <div className={longDescriptionClasses}>
        {
            open && !disableLongDescription && getLongDescription()
        }
      </div>
    </div>
  );
};

Accordion.propTypes = {
  defaultState: PropTypes.bool.isRequired,
  getShortDescription: PropTypes.func.isRequired,
  getLongDescription: PropTypes.func.isRequired,
  disableLongDescription: PropTypes.bool,
};

Accordion.defaultProps = {
  disableLongDescription: false,
};

export default Accordion;
