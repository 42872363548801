import React, { Component } from 'react';
import PropTypes from 'prop-types';


class Login extends Component {
  static isUserBlockingOnError() {
    return true;
  }

  static getDomId() {
    return 'login';
  }

  render() {
    const {
      oauthSigninUrl,
      oauthSigninImageUrl,
      coupsLogoUrl,
    } = this.props;

    return (
      <div className="login__wrapper">
        <div className="login__card">
          <img
            className="login__logo"
            src={coupsLogoUrl}
            alt="Coups! There it is"
          />
          <div className="login__text">
            <div className="login__text--title">
              Welcome
            </div>
            <div className="login__text--subtitle">
              Log in to Uplight to continue to Coups.
            </div>
          </div>
          <a href={oauthSigninUrl} className="login__CTA">
            <img src={oauthSigninImageUrl} alt="Google icon" className="login__cta--icon" />
            <div className="login__CTA--text">
              Continue with Uplight G Suite
            </div>
          </a>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  oauthSigninUrl: PropTypes.string.isRequired,
  oauthSigninImageUrl: PropTypes.string.isRequired,
  coupsLogoUrl: PropTypes.string.isRequired,
};

export default Login;
