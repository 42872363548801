import axios from 'axios';
import { RECEIVE_STORES } from './types';


const receiveStores = stores => ({
  type: RECEIVE_STORES,
  stores,
});

export const getStores = function getAllStores() {
  return function getStoresFromService(dispatch) {
    axios.get('/api/stores')
      .then((result) => {
        dispatch(receiveStores(result.data));
      })
      .catch(error => console.log(error));
  };
};

export const setCurrent = uuid => function setCurrentStore() {
  axios.post('/api/stores/current', { uuid })
    .then(() => { window.location.replace('/campaigns'); })
    .catch(error => console.log(error));
};
