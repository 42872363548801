/* eslint-disable camelcase */
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import PropTypes from 'prop-types';


const Auth0 = ({ children }) => {
  const {
    AUTH0_DOMAIN,
    AUTH0_CLIENT_ID,
    AUTH0_AUDIENCE,
    AUTH0_SCOPE,
  } = window.upCouponAdmin.globals;

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${window.location.protocol}//${window.location.host}`,
        scope: AUTH0_SCOPE,
        audience: AUTH0_AUDIENCE,
        response_type: 'code',
      }}
    >
      { children }
    </Auth0Provider>
  );
};

Auth0.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Auth0;
