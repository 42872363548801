import React from 'react';
import Auth0 from './Auth0';
import ComponentWrapper from './ComponentWrapper';


const withAuthentication = (Component, domId) => {
  // eslint-disable-next-line react/prefer-stateless-function
  class WithAuthentication extends React.Component {
    static getDomId() {
      return domId;
    }

    render() {
      const { ENABLE_AUTH0 } = window.upCouponAdmin.globals;

      // eslint-disable-next-line no-undef
      if (ENABLE_AUTH0 === 'true') {
        return (
          <Auth0>
            <ComponentWrapper>
              <Component {...this.props} />
            </ComponentWrapper>
          </Auth0>
        );
      }

      return <Component {...this.props} />;
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
