const monthMap = {
  1: '01',
  2: '02',
  3: '03',
  4: '04',
  5: '05',
  6: '06',
  7: '07',
  8: '08',
  9: '09',
  10: '10',
  11: '11',
  12: '12',
};

const dayToString = day => day.toString().padStart(2, 0);

const dateFromString = (date = '') => {
  const validDatePattern = /\d{4}-\d{2}-\d{2}/;

  if (!validDatePattern.test(date)) {
    throw new Error('Invalid date');
  }

  const newDate = new Date(date);

  return newDate.toISOString();
};

const dateFromServerResponse = (date = '') => {
  const validDatePattern = /\d{2}\/\d{2}\/\d{4}/;

  if (!validDatePattern.test(date)) {
    throw new Error('Invalid date');
  }

  const newDate = new Date(date);

  return `${newDate.getFullYear()}-${monthMap[newDate.getMonth() + 1]}-${dayToString(newDate.getDate())}`;
};

export default dateFromString;

export {
  dateFromServerResponse,
};
