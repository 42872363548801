import axios from 'axios';
import { RECEIVE_PROMOTIONS, RECEIVE_CURRENT_STORE } from './types';


const receiveCurrentStore = store => ({
  type: RECEIVE_CURRENT_STORE,
  store,
});

const receivePromotions = promotions => ({
  type: RECEIVE_PROMOTIONS,
  promotions,
});

export const getCurrentStore = () => function getStoreFromService(dispatch) {
  axios.get('/api/stores/current')
    .then((result) => {
      dispatch(receiveCurrentStore(result.data));
    })
    .catch(error => console.log(error));
};

export const getPromotions = () => function getPromotionsFromService(dispatch) {
  axios.get('/api/promotions')
    .then((result) => {
      dispatch(receivePromotions(result.data));
    })
    .catch(error => console.log(error));
};
