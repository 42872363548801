import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import validator from '../libs/validator';


const RadioField = ({
  id,
  fieldKey,
  control,
  onChange,
  options: radioOptions = [],
  validation,
  value,
  formData,
}) => {
  const { type } = control;

  const validateField = (userValue) => {
    const errors = validator(userValue, value.initialValue, validation, formData);

    onChange({ value: userValue, isTouched: userValue !== value.initialValue, errors });
  };

  useEffect(() => {
    validateField(value.initialValue);
  }, [value.initialValue, value.formResetUUID]);

  return radioOptions.map(({ label, value: optionValue }) => {
    const { value: userSelectedValue } = value;
    const extraProps = userSelectedValue.toString() === optionValue.toString() ? { checked: 'checked' } : {};

    return (
      <div key={`${id}_${label}`}>
        <input
          id={`${id}_${label}`}
          key={`${id}_${label}`}
          type={type}
          value={optionValue}
          name={fieldKey}
          onChange={(event) => {
            validateField(event.target.value);
          }}
          {...extraProps}
        />
        <span htmlFor={`${id}_${label}`} key={`lable_${id}_${label}`}>{label}</span>
      </div>
    );
  });
};


RadioField.propTypes = {
  control: PropTypes.shape({
    type: PropTypes.string.isRequired,
    options: PropTypes.shape({
      inputProps: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
    initialValue: PropTypes.string.isRequired,
    formResetUUID: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  validation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formData: PropTypes.shape({}).isRequired,
};

export default RadioField;
