const getValueFromWindow = function getValueFromWindowHelper(prop, key) {
  const { upCouponAdmin } = window;

  if (
    !upCouponAdmin ||
    !Object.hasOwnProperty.call(upCouponAdmin, prop) ||
    !Object.hasOwnProperty.call(upCouponAdmin[prop], key)
  ) {
    return '';
  }

  return window.upCouponAdmin[prop][key];
};


export default getValueFromWindow;
