/* eslint-disable camelcase */
const typeMap = {
  retail_discount: 'Retail',
  manufacturer_discount: 'Manufacturer',
  utility_discount: 'Utility',
};

export default function getPromotionType(type) {
  return typeMap[type] || 'Unknown';
}
