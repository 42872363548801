import axios from 'axios';
import {
  RECEIVE_CURRENT_STORE,
  RECEIVE_CAMPAIGN,
  RECEIVE_ALL_PROMOTIONS,
  RECEIVE_COUPONS_FOR_CAMPAIGN,
} from './types';
import { showMessages } from '../../shared/components/StatusToast/actions';
import { ERROR, SUCCESS } from '../../shared/redux/types/responseResult';


const receiveCurrentStore = store => ({
  type: RECEIVE_CURRENT_STORE,
  store,
}
);

const receiveCampaign = campaign => ({
  type: RECEIVE_CAMPAIGN,
  campaign,
});

const receivePromotions = allPromotions => ({
  type: RECEIVE_ALL_PROMOTIONS,
  payload: {
    allPromotions,
  },
});

const receiveCouponsForCampaign = coupons => ({
  type: RECEIVE_COUPONS_FOR_CAMPAIGN,
  payload: {
    coupons,
  },
});

export const getCurrentStore = () => function getStoreFromService(dispatch) {
  axios.get('/api/stores/current')
    .then((result) => {
      dispatch(receiveCurrentStore(result.data));
    })
    .catch(error => console.log(error));
};

export const getCampaign = campaignId => function getCampaign(dispatch) {
  axios.get(`/api/campaigns/${campaignId}`)
    .then((result) => {
      dispatch(receiveCampaign(result.data));
    })
    .catch(error => console.log(error));
};

export const getAllPromotions = () => function getAllPromotions(dispatch) {
  axios.get('/api/promotions/all')
    .then((result) => {
      dispatch(receivePromotions(result.data));
    })
    .catch(error => console.log(error));
};

// eslint-disable-next-line camelcase
export const makeCampaignPromotionAssociation = (campaign_uuid, promotion_uuid) => (dispatch) => {
  // eslint-disable-next-line camelcase
  axios.post('/api/campaignPromotions', { campaign_uuid, promotion_uuid })
    .then(() => {
      dispatch(getCampaign(campaign_uuid));
      dispatch(showMessages([
        {
          message: 'Campaign - Promotion Association successfully',
          type: SUCCESS,
        },
      ]));
    })
    .catch((error) => {
      dispatch(showMessages([
        {
          message: 'Campaign - Promotion Association failed',
          type: ERROR,
        },
      ]));
      console.log(error);
    });
};

// eslint-disable-next-line camelcase
export const deleteCampaignPromotionAssociation = (campaign_uuid, promotion_uuid) => (dispatch) => {
  // eslint-disable-next-line camelcase, id-blacklist
  axios.delete('/api/campaignPromotions', { data: { campaign_uuid, promotion_uuid } })
    .then(() => {
      dispatch(getCampaign(campaign_uuid));
      dispatch(showMessages([
        {
          message: 'Campaign - Promotion Association deleted successfully',
          type: SUCCESS,
        },
      ]));
    })
    .catch((error) => {
      dispatch(showMessages([
        {
          message: 'Failed to delete Association',
          type: ERROR,
        },
      ]));
      console.log(error);
    });
};

// eslint-disable-next-line camelcase
export const getCouponsForCampaign = campaign_uuid => (dispatch) => {
  // eslint-disable-next-line camelcase
  axios.get(`/api/coupons?campaignId=${campaign_uuid}`)
    .then((result) => {
      dispatch(receiveCouponsForCampaign(result.data));
    })
    .catch(error => console.log(error));
};

export const deleteCouponForCampaign = (couponId, campaignId) => (dispatch) => {
  // eslint-disable-next-line id-blacklist
  axios.delete('/api/coupons/', { data: { couponId, campaignId } })
    .then(() => {
      dispatch(getCouponsForCampaign(campaignId));
      dispatch(showMessages([
        {
          message: 'Coupon deleted successfully',
          type: SUCCESS,
        },
      ]));
    })
    .catch((error) => {
      dispatch(showMessages([
        {
          message: 'Failed to delete Coupon',
          type: ERROR,
        },
      ]));
      console.log(error);
    });
};

// eslint-disable-next-line camelcase
export const generateCodes = (coupon_uuid, campaign_uuid, quantity) => (dispatch) => {
  // eslint-disable-next-line camelcase
  axios.post('/api/coupons/codes/generate', { coupon_uuid, campaign_uuid, quantity })
    .then(() => {
      dispatch(getCouponsForCampaign(campaign_uuid));
      dispatch(showMessages([
        {
          message: 'Code generated successfully',
          type: SUCCESS,
        },
      ]));
    })
    .catch((error) => {
      dispatch(showMessages([
        {
          message: 'Failed to generate Codes',
          type: SUCCESS,
        },
      ]));
      console.log(error);
    });
};

export const downloadFile = (link, code) => {
  axios.get(link)
    .then((response) => {
      const tempNode = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = tempNode;
      link.setAttribute('download', `${code}.csv`);
      document.body.appendChild(link);
      link.click();
    });
};
