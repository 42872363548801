import resourceDetailsCreator from '../../shared/redux/reducers/resourceDetailsCreator';
import schemaReducerCreator from '../../shared/redux/reducers/schemaReducerCreatror';


const createResourceReducer = (resource, initialState) => {
  const schemaReducer = schemaReducerCreator(resource);
  const resourceDetailsReducer = resourceDetailsCreator(resource);

  return (state = initialState, action) => {
    const newState = {
      ...state,
      formData: schemaReducer(state.formData, action),
      resourceDetails: resourceDetailsReducer(state.resourceDetails, action),
    };

    return newState;
  };
};

export default createResourceReducer;
