import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteCouponForCampaign, generateCodes, getCouponsForCampaign } from '../actions/campaign';
import CouponCard from './CouponCard';


class CouponsWrapper extends Component {
  constructor(props) {
    super(props);
    this.onDeleteCoupon = this.onDeleteCoupon.bind(this);
    this.onGenerateCoupons = this.onGenerateCoupons.bind(this);
  }

  componentDidMount() {
    const { campaignId, dispatch } = this.props;

    dispatch(getCouponsForCampaign(campaignId));
  }

  onDeleteCoupon(couponId) {
    const { campaignId, dispatch } = this.props;

    dispatch(deleteCouponForCampaign(couponId, campaignId));
  }

  onGenerateCoupons(quantity, couponId) {
    const { campaignId, dispatch } = this.props;

    dispatch(generateCodes(couponId, campaignId, quantity));
  }

  render() {
    const { coupons = [], campaignId } = this.props;

    return (
      <>
        {
          coupons.length ?
            (
              <ul className="campaign__coupons">
                {
                  coupons.map(coupon => (
                    <CouponCard
                      key={coupon.uuid}
                      campaignId={campaignId}
                      onDeleteCoupon={this.onDeleteCoupon}
                      onGenerateCodes={this.onGenerateCoupons}
                      {...coupon}
                    />
                  ))
                }
              </ul>
            ) :
            (
              <div className="campaign__no-assigned-resource">
                <p>No Coupons assigned to this Campaign</p>
              </div>
            )
        }
        <div className="campaign__footer">
          <div className="campaign__footer-item">
            <Link to={`/campaigns/coupons/create/${campaignId}`} className="campaign__footer-item-button">Create Coupon</Link>
          </div>
        </div>
      </>
    );
  }
}

CouponsWrapper.propTypes = {
  campaignId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  coupons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  coupons: state.campaignData.coupons,
});

export default connect(mapStateToProps)(CouponsWrapper);
