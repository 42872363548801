import axios from 'axios';
import { RESET_FORM, UPDATE_FORM_FIELD } from '../types/formActions';
import { showMessages } from '../../components/StatusToast/actions';
import { ERROR, SUCCESS } from '../types/responseResult';
import dateFromString from '../../helpers/dateUtils';
import { updateFormData } from './schema';
import { resourceSelector } from '../selectors/formDataSelectors';


const resetForm = resource => ({
  type: `${RESET_FORM}_${resource}`,
});


const updateFormValues = (resource, formData) => ({
  type: `${UPDATE_FORM_FIELD}_${resource}`,
  payload: {
    isValid: formData.isValid,
    values: formData.values,
  },
});

const convertFieldDataToFieldType = (value, fieldOptions) => {
  const { dataType } = fieldOptions;
  const userUpdatedValues = value.value;

  switch (dataType) {
    case 'boolean': {
      const { map } = fieldOptions;

      return map[userUpdatedValues];
    }
    case 'json': {
      if (typeof userUpdatedValues === 'string') {
        return JSON.parse(userUpdatedValues);
      }
      return userUpdatedValues;
    }
    case 'date': {
      return dateFromString(userUpdatedValues);
    }
    default: {
      return userUpdatedValues;
    }
  }
};

const getDataBody = (config = []) => {
  const bodyData = config.reduce((acc, field) => {
    const {
      id,
      value,
      control = {},
    } = field;

    const { options: { fieldOptions = {} } = {} } = control;

    return {
      ...acc,
      [id]: convertFieldDataToFieldType(value, fieldOptions),
    };
  }, {});

  return bodyData;
};

export const submitFormValues = (resource, formData) => (dispatch, getState) => {
  const body = getDataBody(formData);

  const state = getState();
  const {
    extraParams: {
      queryParams = '',
      bodyParams = {},
    } = {},
  } = resourceSelector(state.resource);

  axios.post(`/api/${resource}?${queryParams}`, { ...body, ...bodyParams })
    .then(response => response.data)
    .then(() => {
      dispatch(showMessages([
        {
          message: `${resource} created successfully`,
          type: SUCCESS,
        },
      ]));
      dispatch(resetForm(resource));
    })
    .catch((error) => {
      dispatch(showMessages([
        {
          message: `${resource} failed with error: ${error}`,
          type: ERROR,
        },
      ]));
    });
};

export const patchFormValues = (resource, formData, resourceId) => (dispatch, getState) => {
  const body = getDataBody(formData);

  const state = getState();
  const {
    extraParams: {
      queryParams = '',
      bodyParams = {},
    } = {},
  } = resourceSelector(state.resource);

  axios.patch(`/api/${resource}/${resourceId}?${queryParams}`, { ...body, ...bodyParams })
    .then(response => response.data)
    .then((updatedResource) => {
      dispatch(showMessages([
        {
          message: `${resource} updated successfully`,
          type: SUCCESS,
        },
      ]));
      dispatch(updateFormData(resource, updatedResource));
    })
    .catch((error) => {
      dispatch(showMessages([
        {
          message: `${resource} failed with error: ${error}`,
          type: ERROR,
        },
      ]));
    });
};

export default updateFormValues;
