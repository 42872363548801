import React from 'react';
import { Link } from 'react-router-dom';
import PropType from 'prop-types';
import { Provider } from 'react-redux';
import StatusBadge from '../../shared/components/StatusBadge/StatusBadge';
import Accordion from '../../shared/components/Accordion/Accordion';
import IconEdit from '../../shared/icons/icon-edit.svg';
import IconTrash from '../../shared/icons/icon-trash.svg';
import IconEditAssociation from '../../shared/icons/icon-edit-association.svg';
import CreateResource from '../../CreateEditResource/CreateResource';
import { FORM_OPERATION_READ } from '../../../config/constants';
import Tooltip from '../../shared/components/Tooltip/Tooltip';


// eslint-disable-next-line max-lines-per-function
const PromotionCard = ({
  uuid,
  name,
  details,
  enabled,
  onDeletePromotionAssociation,
  campaignId,
}) => (
  <li className="campaign__promotion-card">
    <Accordion
      defaultState={false}
      getShortDescription={() => (
        <div className="campaign__promotion">
          <div className="campaign__promotion-left">
            <div className="campaign__promotion-name">{name}</div>
            <div className="campaign__promotion-details">{details}</div>
          </div>
          <div className="campaign__promotion-right">
            <Link className="campaign__promotion-association-edit" to={`/campaigns/campaignPromotions/edit/${campaignId}/${uuid}`}>
              <Tooltip message="Edit Campaign - Promotion Association">
                <IconEditAssociation
                  className="campaign__promotion-edit-assocation"
                />
              </Tooltip>
            </Link>
            <Link className="campaign__promotion-edit" to={`/promotions/edit/${uuid}`}>
              <Tooltip message="Edit Promotion">
                <IconEdit
                  className="campaign__promotion-edit-promotion"
                />
              </Tooltip>
            </Link>
            <Tooltip message="Delete Campaign - Promotion Association">
              <IconTrash
                className="campaign__promotion-remove-association tooltip"
                onClick={(event) => {
                  event.stopPropagation();
                  onDeletePromotionAssociation(uuid);
                }}
              />
            </Tooltip>
            <div className="campaign__promotion-state">
              <StatusBadge status={enabled ? 'active' : 'disabled'} size="medium" />
            </div>
          </div>
        </div>
      )}
      getLongDescription={() => (
        <Provider store={CreateResource.getStore('promotions')}>
          <CreateResource
            title={name}
            resource="promotions"
            resourceDetails={{
              operation: FORM_OPERATION_READ,
              resourceId: uuid,
            }}
          />
        </Provider>
      )}
    />
  </li>
);

PromotionCard.propTypes = {
  name: PropType.string.isRequired,
  details: PropType.string.isRequired,
  enabled: PropType.bool.isRequired,
  uuid: PropType.string.isRequired,
  onDeletePromotionAssociation: PropType.func.isRequired,
  campaignId: PropType.string.isRequired,
};

export default PromotionCard;
