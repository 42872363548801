export const LOCAL_PORT = 5000; // eslint-disable-line
export const HOME_URL = '/';
export const SIGNIN_URL = '/api/oauth/init';
export const LOGOUT_URL = '/api/logout';
export const OAUTH_URL = '/api/oauth';
export const OAUTH_REDIRECT_URL = '/api/oauth/redirect';
export const OAUTH_HANDLER_URL = '/api/oauth/handler';
export const STATE_COOKIE_NAME = 'UP_STID';
export const SESSION_COOKIE_NAME = 'UP_SEID';
export const RETURN_URL_COOKIE_NAME = 'UP_REDIRECT';
export const ADMIN_ROLE = 'discounts:write';
export const READ_ONLY_ROLE = 'discounts:read';
export const REACT_SENTRY_DSN = '';

export const FORM_OPERATION_EDIT = 'FORM_EDIT';
export const FORM_OPERATION_CREATE = 'FORM_CREATE';
export const FORM_OPERATION_READ = 'FORM_READ';

export const FORM_OPERATION_BTN_LABEL = {
  [FORM_OPERATION_CREATE]: 'Create',
  [FORM_OPERATION_EDIT]: 'Update',
};
