import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import StoreSelector from '../../StoreSelector';


const StoreSelectorContainer = function StoreSelectorContainer({ currentStore }) {
  const storeSelected = currentStore !== undefined;

  return (
    <div className={storeSelected ? 'store-selector__heading' : 'store-selector__container'}>
      <Provider store={StoreSelector.getStore()}>
        <StoreSelector currentStore={currentStore} />
      </Provider>
    </div>
  );
};

StoreSelectorContainer.propTypes = {
  currentStore: PropTypes.string,
};

StoreSelectorContainer.defaultProps = {
  currentStore: undefined,
};

export default StoreSelectorContainer;
