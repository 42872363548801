const shouldValidateField = (formData, dependsOn) => {
  const { type } = dependsOn;

  switch (type) {
    case 'fieldDependancy': {
      const { field, condition: { fieldValue } } = dependsOn;
      const fieldConfig = formData.find(fieldConfig => fieldConfig.id === field);

      if (!fieldConfig) {
        return true;
      }

      const { value: { value = '' } = {} } = fieldConfig;

      return value === fieldValue;
    }
    default: return true;
  }
};

const validator = (value, initialValue, validations, formData) => {
  // eslint-disable-next-line complexity
  const errors = validations.map((validation) => {
    const {
      isRequired,
      message,
      pattern = /(.*?)/,
      dependsOn,
    } = validation;

    let validate = true;

    if (dependsOn) {
      validate = shouldValidateField(formData, dependsOn);
    }

    if (validate && isRequired && value === '') {
      return message;
    }

    if (validate && !new RegExp(pattern).test(value)) {
      return message;
    }

    return null;
  });

  return errors.filter(error => error);
};

export default validator;
