import React from 'react';
import PropTypes from 'prop-types';
import StoreSelectorContainer from '../StoreSelector/components/StoreSelectorContainer/StoreSelectorContainer';


const LandingPage = function LandingPageComponent({ landingIcon }) {
  return (
    <div className="landing-page">
      <div className="landing-page__container">
        <img src={landingIcon} alt="Landing Icon" className="landing-page-logo" />
        <div className="landing-page-intro">
          <StoreSelectorContainer currentStore={undefined} />
        </div>
      </div>
    </div>
  );
};

LandingPage.propTypes = {
  landingIcon: PropTypes.string.isRequired,
};

export default LandingPage;
