import React from 'react';
import PropTypes from 'prop-types';


const GenerateCodes = ({
  onUpdateQuantity,
}) => (
  <div className="generate-code__container">
    <div className="generate-code__number-container">
      {
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label htmlFor="code_quantity" className="generate-code__label">Select Quantity</label>
      }

      <input
        type="number"
        id="code_quantity"
        onChange={event => onUpdateQuantity(parseInt(event.target.value, 10))}
        min={0}
      />
    </div>
  </div>
);


GenerateCodes.propTypes = {
  onUpdateQuantity: PropTypes.func.isRequired,
};

export default GenerateCodes;
