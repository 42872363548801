import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { deleteCampaignPromotionAssociation, getAllPromotions, makeCampaignPromotionAssociation } from '../actions/campaign';
import PromotionCard from './PromotionCard';
import Modal from '../../shared/components/Modal/Modal';
import AssignPromotion from './AssignPromotion';


class PromotionsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promotionForAssocication: '',
      showModal: false,
    };

    this.onSelectPromotion = this.onSelectPromotion.bind(this);
    // eslint-disable-next-line max-len
    this.onSubmitCampaingPromotionAssociation = this.onSubmitCampaingPromotionAssociation.bind(this);
    this.onDeletePromotionAssociation = this.onDeletePromotionAssociation.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onModalVisibilityChange = this.onModalVisibilityChange.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAllPromotions());
  }

  onSelectPromotion(selectedPromotionUUID) {
    this.setState({ promotionForAssocication: selectedPromotionUUID });
  }

  onSubmitCampaingPromotionAssociation() {
    const { promotionForAssocication } = this.state;
    const { dispatch, campaignId } = this.props;

    dispatch(makeCampaignPromotionAssociation(campaignId, promotionForAssocication));
    this.setState({ showModal: false, promotionForAssocication: '' });
  }

  onDeletePromotionAssociation(promotionId) {
    const { dispatch, campaignId } = this.props;

    dispatch(deleteCampaignPromotionAssociation(campaignId, promotionId));
  }

  onCloseModal() {
    this.setState({ showModal: false });
  }

  onModalVisibilityChange(visibility) {
    this.setState({ showModal: visibility });
  }

  getModalActions() {
    return [
      {
        formActionName: 'Assign',
        onAction: this.onSubmitCampaingPromotionAssociation,
        // eslint-disable-next-line react/destructuring-assignment
        ...this.state.promotionForAssocication === '' && { disabled: 'disabled' },
      },
      {
        formActionName: 'Cancel',
        onAction: this.onCloseModal,
      },
    ];
  }

  render() {
    const { campaignPromotions, allPromotions, campaignId } = this.props;
    const { showModal } = this.state;

    return (
      <>
        {
            campaignPromotions.length ?
              (
                <ul className="campaign__promotions">
                  {campaignPromotions.map(promotion => (
                    <PromotionCard
                      {...promotion}
                      coupons={[]}
                      key={promotion.uuid}
                      onDeletePromotionAssociation={this.onDeletePromotionAssociation}
                      campaignId={campaignId}
                    />
                  ))}
                </ul>
              ) :
              (
                <div className="campaign__no-assigned-resource">
                  <p>No Promotion assigned to this Campaign</p>
                </div>
              )
            }
        <div className="campaign__footer">
          <div className="campaign__footer-item">
            <Modal
              showModal={showModal}
              onModalVisibilityChange={this.onModalVisibilityChange}
              modalActions={this.getModalActions()}
              form={(
                <AssignPromotion
                  allPromotions={allPromotions}
                  onSelectPromotion={this.onSelectPromotion}
                />
                  )}
            >
              <div className="modal__action_button">Assign Promotion</div>
            </Modal>
          </div>
          <div className="campaign__footer-item">
            <Link to="/promotions/create" className="campaign__footer-item-button">Create Promotion</Link>
          </div>
        </div>
      </>
    );
  }
}

PromotionsWrapper.propTypes = {
  dispatch: PropTypes.func.isRequired,
  campaignId: PropTypes.string.isRequired,
  campaignPromotions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allPromotions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  allPromotions: state.campaignData.allPromotions,
});

export default connect(mapStateToProps)(PromotionsWrapper);
