import axios from 'axios';
import { RECEIVE_CAMPAIGNS, RECEIVE_CURRENT_STORE } from './types';


const receiveCurrentStore = store => ({
  type: RECEIVE_CURRENT_STORE,
  store,
});

const receiveCampaigns = campaigns => ({
  type: RECEIVE_CAMPAIGNS,
  campaigns,
});

export const getCurrentStore = () => function getStoreFromService(dispatch) {
  axios.get('/api/stores/current')
    .then((result) => {
      dispatch(receiveCurrentStore(result.data));
    })
    .catch(error => console.log(error));
};

export const getCampaigns = () => function getCampaignsFromService(dispatch) {
  axios.get('/api/campaigns')
    .then((result) => {
      dispatch(receiveCampaigns(result.data));
    })
    .catch(error => console.log(error));
};
