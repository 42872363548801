import { v4 as formResetUUIDGenerator } from 'uuid';
import { FETCH_SCHEMA_ERROR, FETCH_SCHEMA_SUCCESS } from '../types/fetchSchema';
import {
  CLEAR_FORM, RESET_FORM, UPDATE_FORM_DATA, UPDATE_FORM_FIELD,
} from '../types/formActions';
import { dateFromServerResponse } from '../../helpers/dateUtils';


const getUpdateFormFields = (updatedValues = {}, config) => {
  const { values } = updatedValues;
  const { isValid } = updatedValues;
  const updatedConfig = config.map((field) => {
    const updatedField = { ...field };
    const {
      control: {
        options: {
          fieldOptions = {},
        } = {},
      } = {},
    } = updatedField;

    const value = values[field.id] || field.value;
    const { value: userUpdatedValue } = value;

    if (fieldOptions.dataType === 'json' && typeof userUpdatedValue === 'string') {
      try {
        updatedField.value.value = JSON.parse(userUpdatedValue);
        const errorIndex = updatedField.value.errors.indexOf('Invalid Json');

        if (errorIndex > -1) {
          updatedField.value.errors.splice(errorIndex, 1);
        }
      } catch (exception) {
        updatedField.value.value = userUpdatedValue;
        updatedField.value.errors = ['Invalid Json'];
      }
    } else {
      updatedField.value = { ...updatedField.value, ...value, value: userUpdatedValue };
    }

    return updatedField;
  });

  return { updatedConfig, isValid };
};


const getFieldValueFromFieldType = (field, resource) => {
  const { control = {}, fieldKey } = field;
  const { options: { fieldOptions = {} } = {} } = control;
  const { dataType } = fieldOptions;

  switch (dataType) {
    case 'date': {
      return dateFromServerResponse(resource[fieldKey]);
    }

    default: {
      return resource[fieldKey];
    }
  }
};


const getUpdatedFieldsWithUiData = (config, resourceData) => config.map((field) => {
  const { fieldKey } = field;
  let fieldValue = null;

  if (resourceData) {
    /*
     * In case of Campaigns in get request language metadata is not present in the response body
     */
    if (Object.prototype.hasOwnProperty.call(resourceData, fieldKey)) {
      fieldValue = getFieldValueFromFieldType(field, resourceData);
    } else {
      fieldValue = field.value.value;
    }
  } else {
    fieldValue = field.value;
  }

  return {
    ...field,
    value: {
      value: fieldValue,
      isTouched: false,
      errors: [],
      initialValue: fieldValue,
      formResetUUID: formResetUUIDGenerator(),
    },
  };
});

const resetForm = config => config.map(field => ({
  ...field,
  value: {
    value: field.value.initialValue,
    isTouched: false,
    errors: [],
    initialValue: field.value.initialValue,
    formResetUUID: formResetUUIDGenerator(),
  },
}));

const schemaReducerCreator = (
  resource,
  initialState = { isValid: false },
// eslint-disable-next-line complexity
) => (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_SCHEMA_SUCCESS}_${resource}`: {
      const { formData = {} } = action.payload || {};
      const updatedUiData = getUpdatedFieldsWithUiData(formData);

      return { ...state, config: updatedUiData };
    }
    case `${CLEAR_FORM}_${resource}`:
    case `${FETCH_SCHEMA_ERROR}_${resource}`: {
      return { ...state, config: [] };
    }
    case `${RESET_FORM}_${resource}`: {
      return { ...state, config: resetForm(state.config) };
    }
    case `${UPDATE_FORM_FIELD}_${resource}`: {
      const { updatedConfig, isValid } = getUpdateFormFields(action.payload, state.config);

      return {
        ...state,
        config: updatedConfig,
        isValid,
      };
    }
    case `${UPDATE_FORM_DATA}_${resource}`: {
      const { payload } = action;
      const updatedUiData = getUpdatedFieldsWithUiData(state.config, payload);

      return { ...state, config: updatedUiData };
    }
    default: {
      return state;
    }
  }
};

export default schemaReducerCreator;
