import ComponentLoader from './lib/ComponentLoader';
import Login from './Login/Login';
import CouponAdmin from './CouponAdmin/CouponAdmin';


const componentLoader = new ComponentLoader([
  Login,
  CouponAdmin,
]);

componentLoader.load();
