import { createSelector } from 'reselect';


const formDataSelector = createSelector(
  state => state.formData || {},
  ({ config = [] }) => config,
);

export const formValiditySelector = createSelector(
  formDataSelector,
  config => config
    .reduce((acc, field) => [...acc, ...field.value.errors], [])
    .length > 0,
);

export const resourceSelector = createSelector(
  state => state,
  ({ resourceDetails }) => resourceDetails,
);

export default formDataSelector;
