import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';


const Tooltip = ({ message, children }) => {
  const containerRef = useRef(null);
  const [classNames, setClassNames] = useState('center');
  const RIGHT_THRESHOLD = 100;

  const onVisibilityChange = () => {
    const element = containerRef.current;
    const { width, x: rectX } = element.getBoundingClientRect();

    setClassNames(classnames({
      right: (width + rectX + RIGHT_THRESHOLD) > window.innerWidth,
      center: (width + rectX + RIGHT_THRESHOLD) < window.innerWidth,
    }));
  };

  return (
    <div className="tooltip" onMouseEnter={onVisibilityChange}>
      {children}
      <span className={classNames} ref={containerRef}>{message}</span>
    </div>
  );
};

Tooltip.propTypes = {
  message: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default Tooltip;
