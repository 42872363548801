import { RECEIVE_PROMOTIONS, RECEIVE_CURRENT_STORE } from '../actions/types';


export default function promotionsReducer(state, action) {
  switch (action.type) {
    case RECEIVE_CURRENT_STORE:
      return {
        ...state,
        store: action.store,
      };
    case RECEIVE_PROMOTIONS:
      return {
        ...state,
        promotions: action.promotions,
      };
    default:
      return state;
  }
}
