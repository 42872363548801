import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import validator from '../libs/validator';


const TextAreaField = ({
  control,
  value,
  onChange,
  validation,
  id,
  fieldKey,
  formData,
}) => {
  const { type, options } = control;
  const { fieldOptions: { dataType } = {}, inputProps = {} } = options;

  const validateField = (userValue) => {
    const errors = validator(userValue, value.initialValue, validation, formData);

    onChange({ value: userValue, isTouched: userValue !== value.initialValue, errors });
  };

  useEffect(() => {
    validateField(value.initialValue);
  }, [value.initialValue, value.formResetUUID]);

  const getStringValue = value => ((value &&
        dataType === 'json' &&
        typeof value === 'object') ?
    JSON.stringify(value, null, 4) :
    value);


  return (
    <textarea
      id={id}
      name={fieldKey}
      className="form-field__control-textarea"
      type={type}
      value={getStringValue(value.value)}
      onChange={(event) => {
        validateField(event.target.value);
      }}
      {...inputProps}
    />
  );
};

TextAreaField.propTypes = {
  control: PropTypes.shape({
    type: PropTypes.string.isRequired,
    options: PropTypes.shape({
      inputProps: PropTypes.shape({}).isRequired,
      fieldOptions: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
    initialValue: PropTypes.string.isRequired,
    formResetUUID: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  validation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formData: PropTypes.shape({}).isRequired,
};

export default TextAreaField;
