import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import StatusBadge from '../../shared/components/StatusBadge/StatusBadge';


const CampaignRow = function CampaignRowComponent({
  uuid, name, createdAt, startsAt, endsAt, status, totalCodes, type,
}) {
  return (
    <>
      <td className="promotions__campaign">
        <Link
          className="promotions__campaign-edit"
          to={`/campaigns/${uuid}/edit`}
        >
          {name}
        </Link>
      </td>
      <td className="promotions__dates">
        {startsAt}
        &ndash;
        {endsAt || 'Ongoing'}
      </td>
      <td className="promotions__status">
        <StatusBadge status={status} />
      </td>
      <td className="promotions__codes">
        <div className="promotions__codes-total">
          {totalCodes}
          <span
            className="promotions__export"
            onClick={() => {}}
            onKeyPress={() => {}}
            role="button"
            tabIndex={0}
          >
            Export Codes
          </span>
        </div>
      </td>
      <td className="promotions__type">{type}</td>
      <td className="promotions__created">{createdAt}</td>
    </>
  );
};

CampaignRow.propTypes = {
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  startsAt: PropTypes.string.isRequired,
  endsAt: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  totalCodes: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default CampaignRow;
