import STORE_RESOURCE_DETAILS from '../types/resourceDetails';


const resourceDetailsCreator = (resource, intialState) => (state = intialState, action) => {
  switch (action.type) {
    case `${STORE_RESOURCE_DETAILS}_${resource}`: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default resourceDetailsCreator;
