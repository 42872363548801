import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';


const Tabs = ({ tabsConfiguration, defaultTab, children }) => {
  const [selectedTab, updateSelectedTab] = useState(defaultTab);

  useEffect(() => {
    if (defaultTab === '' && tabsConfiguration.length > 0) {
      const tabKey = tabsConfiguration[0].key;

      updateSelectedTab(tabKey);
    }
  }, [defaultTab]);

  const onSelectTab = (tabKey) => {
    updateSelectedTab(tabKey);
  };

  return (
    <div className="tabs__container">
      <div className="tabs__header">
        {
          tabsConfiguration.map(({ key, title, disabled }, index) => {
            const className = classNames(
              'tabs__tab-header',
              { 'tabs__tab-header-selected': key === selectedTab },
              { 'tabs__tab-header-disabled': disabled },
            );

            return (
              <div
                className={className}
                role="button"
                onClick={() => !disabled && onSelectTab(key)}
                key={key}
                tabIndex={index}
                onKeyPress={() => !disabled && onSelectTab(key)}
              >
                <span>{title}</span>
              </div>
            );
          })
        }
      </div>
      <div className="tabs__content-container">
        {
          React.Children.map(children, (child) => {
            const { key } = child;

            return key === selectedTab ? child : null;
          })
        }
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  defaultTab: '',
};

Tabs.propTypes = {
  tabsConfiguration: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      disabled: PropTypes.bool.isRequired,
      key: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  defaultTab: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default Tabs;
