import React, { Component } from 'react';
import PropTypes from 'prop-types';


export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.getModal = this.getModal.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { showModal } = props;
    const { open } = state;

    if (showModal !== open && open === true) {
      return {
        ...state,
        open: false,
      };
    }

    return null;
  }

  getModal() {
    const { open } = this.state;
    const { modalActions, form } = this.props;

    if (open) {
      return (
        <div className="modal__container">
          <div className="modal__card">
            <div
              className="modal__card-close"
              onClick={this.toggleModal}
              role="button"
              tabIndex={0}
              onKeyPress={this.toggleModal}
            >
              X
            </div>
            <div className="modal__card-content">
              <div className="modal__card-content-form">
                {form}
              </div>
              <div className="modal__card-content-footer">
                {
                    modalActions.map((action) => {
                      const { onAction, formActionName, ...rest } = action;

                      return (<button type="button" className="modal__action_button" onClick={onAction} {...rest}>{formActionName}</button>);
                    })
                  }
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  toggleModal(event) {
    event.stopPropagation();
    this.setState(prevState => ({ ...prevState, open: !prevState.open }));

    const { onModalVisibilityChange } = this.props;
    const { open } = this.state;

    onModalVisibilityChange(!open);
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        <button onClick={this.toggleModal} type="button" className="modal__action_button-transparent">{children}</button>
        {this.getModal()}
      </div>
    );
  }
}

Modal.propTypes = {
  modalActions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  form: PropTypes.element.isRequired,
  onModalVisibilityChange: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
